<template>
  <div class="delete-message-background">
    <div class="delete-message-wrapper">
      <div class="header">
        <div class="title-header">
          <p class="title">{{ $t('reports.deleted_intervals_messages') }}</p>
          <CloseIcon class="close" @click.native="closeModal" />
        </div>
        <p class="name-unit">{{ unitItem.name }}</p>
        <el-date-picker
          class="mt-3 deleteMessagePickerOne"
          v-model="dateMessage.datePicker"
          popper-class="reportsDatepicker"
          type="datetimerange"
          range-separator="-"
          value-format="yyyy-MM-dd HH:mm:ss"
          :clearable="false"
          :start-placeholder="$t('reports.not-select')"
          :end-placeholder="$t('reports.not-select')"
          :picker-options="pickerOptions"
          :format="'yyyy-MM-dd HH:mm:ss'"
          @paste.native="onPaste"
          @change="getMessages"
        />
        <div class="header-list">
          <p class="name">{{ $t('reports.interval') }}</p>
          <p
            v-if="!isOpenIntervalWindow"
            class="name-color"
            @click="openIntervalWindow"
          >
            {{ $t('reports.exclude_interval') }}
          </p>
          <p v-else class="name-color" @click="isOpenIntervalWindow = false">
            <CancelIcon />
            {{ $t('cancel') }}
          </p>
        </div>
      </div>
      <div class="messages">
        <div
          class="message"
          v-for="message in listDeletionsMessage.list"
          :key="message.id"
        >
          <p class="date">
            {{ message.start_date }} - {{ message.finish_date }}
          </p>
          <el-tooltip
            :open-delay="300"
            effect="dark"
            :content="'Восстановить сообщения присланные в данном интервале'"
            placement="bottom"
            :offset="2"
            :visible-arrow="false"
          >
            <OutTrashIcon
              class="icon"
              @click.native="removeMessage(message.id)"
            />
          </el-tooltip>
        </div>
      </div>
      <div v-if="isOpenIntervalWindow" class="interval-window">
        <div class="description">
          <InfoIcon />
          <p class="text">
            {{ $t('reports.interval_info') }}
          </p>
        </div>
        <p class="title">{{ $t('reports.interval_for_deletion') }}</p>
        <el-date-picker
          class="picker deleteMessagePicker"
          v-model="dataInterval.datePicker"
          popper-class="reportsDatepicker"
          type="datetimerange"
          range-separator="-"
          value-format="yyyy-MM-dd HH:mm:ss"
          :clearable="false"
          :start-placeholder="$t('reports.not-select')"
          :end-placeholder="$t('reports.not-select')"
          :picker-options="pickerOptions"
          :format="'yyyy-MM-dd HH:mm:ss'"
          @paste.native="onPaste"
          @change="getIntervalMessage"
        />
        <div class="buttons">
          <skif-button
            class="primary btn"
            @click="isOpenIntervalWindow = false"
          >
            {{ $t('cancel') }}
          </skif-button>
          <skif-button
            class="secondary btn"
            @click.native="deleteIntervalMessage"
          >
            {{ $t('delete') }}
          </skif-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/components/map/tools/images/CloseIcon.vue'
import OutTrashIcon from '../../icons/OutTrashIcon.vue'
import InfoIcon from '../../icons/InfoIcon.vue'
import CancelIcon from '../../icons/CancelIcon.vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DeleteMessageModal',
  components: {
    CloseIcon,
    OutTrashIcon,
    InfoIcon,
    CancelIcon
  },
  props: {
    unitItem: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      dateMessage: {
        datePicker: ['', '']
      },
      dataInterval: {
        datePicker: ['', '']
      },
      dateFormat: '',
      isOpenIntervalWindow: false
    }
  },

  computed: {
    ...mapGetters({
      listDeletionsMessage: 'reports/listDeletionsMessage'
    })
  },

  methods: {
    ...mapActions({
      getListDeletionsMessage: 'reports/getListDeletionsMessage',
      deleteMessage: 'reports/deleteMessage',
      removeIntervalMessage: 'reports/deleteIntervalMessage'
    }),

    removeMessage(id) {
      this.deleteMessage(id)
    },

    closeModal() {
      this.$emit('close')
    },

    onPaste(event) {
      const datesMatch = event.clipboardData
        .getData('text')
        .match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}/g)
      const dates = [`${datesMatch[0]}:00`, `${datesMatch[1]}:00`]
      if (
        dates.length === 2 &&
        moment(dates[0], 'yyyy-MM-dd HH:mm:ss').isValid() &&
        moment(dates[1], 'yyyy-MM-dd HH:mm:ss').isValid()
      ) {
        this.dateMessage.datePicker = dates
      }
    },

    getIntervalMessage(value) {
      this.dataInterval.datePicker = [value[0], value[1]]
    },

    getMessages(value) {
      const form = {
        conditions: [
          {
            field: 'unit.id',
            value: this.unitItem.id
          },
          {
            field: 'start_date',
            value: value[0]
          },
          {
            field: 'finish_date',
            value: value[1]
          }
        ]
      }
      this.getListDeletionsMessage(form)
    },

    openIntervalWindow() {
      this.isOpenIntervalWindow = true
    },

    deleteIntervalMessage() {
      const form = {
        start_date: this.dataInterval.datePicker[0],
        finish_date: this.dataInterval.datePicker[1],
        unit: {
          id: this.unitItem.id
        }
      }
      this.removeIntervalMessage(form)
      this.isOpenIntervalWindow = false
    }
  },

  mounted() {
    const form = {
      conditions: [
        {
          field: 'unit.id',
          value: this.unitItem.id
        },
        {
          field: 'start_date',
          value: moment().subtract(5, 'years').format('YYYY-MM-DD HH:mm:ss')
        },
        {
          field: 'finish_date',
          value: moment().format('YYYY-MM-DD HH:mm:ss')
        }
      ]
    }
    this.dateMessage.datePicker = [
      form.conditions[1].value,
      form.conditions[2].value
    ]
    this.dataInterval.datePicker = [
      moment().format('YYYY-MM-DD HH:mm:ss'),
      moment().format('YYYY-MM-DD HH:mm:ss')
    ]
    this.getListDeletionsMessage(form)
  }
}
</script>

<style lang="stylus" scoped>
.delete-message-background
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  background rgba(0,0,0, 0.3)
  z-index 21
.delete-message-wrapper
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  width 400px
  height 600px
  z-index 22
  background white
  border-radius 2px
  .header
    padding 22px
    padding-bottom 13px
    border-bottom 1px solid #CAD6DF
  .title-header
    display flex
    justify-content space-between
    align-items center
    .close
      cursor pointer
  .title
    font-weight 700
    font-size 22px
    line-height 120%
    color #446C9D

  .name-unit
    margin-top 2px
    font-weight 600
    font-size 14px
    line-height 120%
    color #C8D6DF
  .header-list
    display flex
    justify-content space-between
    align-items center
    margin-top 16px
    .name, .name-color
      color #81ABE1
      font-weight 600
      font-size 14px
      line-height 120%
    .name-color
      cursor pointer
      color #476E9F
  .messages
    padding 8px 25px
    .message
      display flex
      justify-content space-between
      align-items center
      margin-bottom 16px
      .date
        font-weight 600
        font-size 14px
        line-height 120%
        color #476E9F
        .close
          cursor pointer
      &:last-child
        margin-bottom 0
  .interval-window
    position absolute
    top 170px
    background white
    .description
      display grid
      grid-template-columns auto auto
      align-items center
      padding 20px 25px 16px 25px
      .text
        margin-left 18px
        font-weight 400
        font-size 14px
        line-height 100%
        color #B9C5CE
    .title
      padding 0 25px 0px 25px
      color #81ABE1
      font-weight 600
      font-size 14px
      line-height 120%
    .picker
      margin 12px 20px
    .buttons
      display flex
      padding 12px 20px 20px 20px
      border-top 1px solid #CAD6DF
      box-shadow 6px 6px 10px rgba(0, 0, 0, 0.16);
      .btn
        width 100%
      .primary
        background #C8D6DF
        margin-right 16px
</style>

<style lang="styl">
.deleteMessagePicker
  margin 12px 20px
  .el-range-separator
    margin-bottom 6px
.deleteMessagePickerOne
  .el-range-separator
    margin-bottom 6px
</style>
