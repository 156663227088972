import { render, staticRenderFns } from "./DeleteMessageModal.vue?vue&type=template&id=321615f0&scoped=true"
import script from "./DeleteMessageModal.vue?vue&type=script&lang=js"
export * from "./DeleteMessageModal.vue?vue&type=script&lang=js"
import style0 from "./DeleteMessageModal.vue?vue&type=style&index=0&id=321615f0&prod&lang=stylus&scoped=true"
import style1 from "./DeleteMessageModal.vue?vue&type=style&index=1&id=321615f0&prod&lang=styl"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "321615f0",
  null
  
)

export default component.exports